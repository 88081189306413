

@media all and (max-width: 500px) {
  table.product-price, table.product-price tr, table.product-price tr td, table.product-price tbody {
    display: block;
  }

  table.product-price tr, table.product-price tr td {
    width: 100%;
  }

  table.product-name thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table.product-name tr {
    border-bottom: 3px solid #ddd;
    display: block;
  }
  
  table.product-name td {
    border-bottom: 1px solid #ddd;
    display: block;
    text-align: right;
  }
  
  table.product-name td::before {
    content: attr(title);
    float: left;
  }

}