#print-label-component * {
  color: black;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0,0,0,.05);
}

#print-label-component .table-bordered th,
#print-label-component .table-bordered td {
  border: 1px solid #000;
}

#print-label-component table td {
  padding: .5rem;
}

#print-label-component table td p {
  margin: 0;
}

#print-label-component hr {
  border: 0;
  border-top: 1px solid #000;
}

.qc-step-title{
  font-size: 1rem !important;
  white-space: nowrap;
}

#print-label-component td {
  white-space: normal !important;
}