td.white-space-normal {
  white-space: normal;
}

td.white-space-nowrap {
  white-space: nowrap;
}

td.white-space-pre {
  white-space: pre;
}

#table-order tr td {
  white-space: normal;
}